/* App.css */

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
  padding-top: 0px; /* To prevent content overlap with NavBar */
}

/* Container */
.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

/* NavBar */
nav {
  background-color: #333;
  color: white;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a,
nav ul li button {
  color: white;
  text-decoration: none;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
}

nav ul li a:hover,
nav ul li button:hover {
  text-decoration: underline;
}

/* LoginPage */
.login-form {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form label {
  display: block;
  margin-bottom: 5px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background: #0056b3;
}

/* Paper Elements */
.MuiPaper-root {
  padding: 20px;
  margin-bottom: 20px;
  background: white;
}

/* Typography */
.MuiTypography-h6 {
  margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
}
