.campaign-container {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 700px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1000;
  border-radius: 8px;
 padding: 20px;
  margin: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.campaign-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.campaign-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.campaign-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.campaign-form .MuiTextField-root {
  margin: 10px 0;
}

.campaign-form .MuiButton-root {
  align-self: flex-start;
}

table {
  margin-top: 20px;
  width: 100%;
}

table th, table td {
  padding: 10px;
  text-align: left;
}

button {
  margin: 10px 0;
}
