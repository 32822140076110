/* MLSRecords.css */

.mls-records-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Summary and search */
.mls-records-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.search-input {
  padding: 5px;
  margin-right: 10px;
}

.records-per-page-select {
  padding: 5px;
}

/* Date filter */
.filter-date {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.filter-date label {
  margin-right: 10px;
}

/* Column filters */
.column-filters {
  margin-bottom: 10px;
}

.column-filters label {
  margin-right: 10px;
}

/* Column summaries */
.column-summaries {
  margin-bottom: 20px;
}

.column-summary {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.column-summary label {
  margin-right: 10px;
}

.column-summary select {
  padding: 5px;
  margin-right: 10px;
}

.summary-result {
  font-weight: bold;
}

/* Table */
.mls-records-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.mls-records-table th,
.mls-records-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  cursor: pointer; /* Make headers clickable */
}

.mls-records-table th {
  background-color: #f2f2f2;
}

.mls-records-table tfoot td {
  font-weight: bold;
  background-color: #f9f9f9;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.page-button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #007bff;
  border-radius: 3px;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
}

.page-button.active,
.page-button:hover {
  background-color: #007bff;
  color: #fff;
}

/* Loading and error messages */
.loading {
  text-align: center;
  font-size: 1.5em;
  color: #4caf50;
}

.error {
  text-align: center;
  font-size: 1.5em;
  color: #f44336;
}
