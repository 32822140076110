.analysis-container {
  padding: 20px;
}

.analysis-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.analysis-results {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.result-item {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}

.result-header {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.listing-item {
  margin-left: 20px;
  margin-top: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.listing-item p {
  margin: 5px 0;
}

.table-header-cell {
  font-weight: 900 !important;
  background-color: #f5f5f5;
  color: #000;
  border: 1px solid;
  border-bottom: 1px solid !important;
}