nav {
  background-color: #007bff;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end; /* Aligns the menu icon to the right */
  align-items: center;
  position: relative;
  height: 50px; /* Adjust height as needed */
}

.menu-icon {
  cursor: pointer;
  font-size: 20px;
  color: white;
  padding-right: 15px;
  font-style: normal; /* Ensures the icon text is not italicized */
}

.nav-links {
  list-style: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px; /* Position directly below the nav bar */
  right: 0; /* Aligns the dropdown to the right */
  background-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
  border-radius: 5px; /* Optional: Rounds corners */
  padding: 0;
  margin: 0;
  width: auto; /* Allows the dropdown to adjust to content width */
}

.nav-links li {
  white-space: nowrap; /* Prevents breaking of list items */
}

.nav-links a,
.nav-links button {
  color: white;
  text-decoration: none;
  padding: 10px 20px; /* Adjust padding as needed */
  display: block;
  text-align: left;
}

.nav-links button {
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    display: none; /* Hide by default, shown when menu is open */
    flex-direction: column;
  }

  .nav-links.open {
    display: flex; /* Display flex to accommodate vertical layout */
  }
}
