

.home-container h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.dialog-title {
  background-color: #1976d2; /* Primary color */
  color: white;
  padding: 16px;
  font-size: 1.25rem;
}

.dialog-content {
  padding: 24px;
}

.dialog-actions {
  padding: 16px;
  justify-content: space-between;
}

