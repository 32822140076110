.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  transition: background-color 0.2s ease-in-out;
}

.dropzone.active {
  background-color: #e0f7fa;
}

.upload-label {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}

.tableCellContent {
  display: flex;
  flex-direction: column;  
  align-items: flex-start;
  justify-content: center;
  gap: 4px; 
}

.tableCellImage {
  width: 100px;  
  height: auto;
  margin-bottom: 4px;  
}
/* Add to your CSS file */
.tableCellImage {
  width: 100px;  
  height: auto;
}

.listingDetailContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.listingInfo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  
  grid-template-rows: repeat(3, auto);  
  column-gap: 8px;
  row-gap: 4px;
  flex: 1;  
  padding-left: 16px;  
}

.listingInfoText {
  margin: 0;
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Use for TableCell that needs specific flex alignment */
.flexTableCell {
  display: flex;
  align-items: center;
}

