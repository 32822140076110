.home-container {
  text-align: center;
  margin-top: 50px;
}

h1 {
  color: #333;
}

p {
  font-size: 1.2em;
  color: #666;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}
